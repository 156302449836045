// modification modal original de Bootstrap
.modal-content {
  text-align: center;
  align-items: center;
}

.form-text {
  color: #d25656;
  margin-bottom: spacing(3);
}
