.privacy {

  &__title {
    margin: spacing(4) 0 spacing(2) 0;
    color: $color-visite;
    font-size: 1.5rem;
  }

  &__text {

  }
}
