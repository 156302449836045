html {
  font-size: 16px;
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  background-color: $color-white;
  color: $color-text;
  font-family: $f-metropolis;
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// (1) fixer le footer en bas de page

.app {
  min-height: 100vh; // (1)
  display: flex; // (1)
  flex-direction: column; // (1)
  justify-content: center;
}

.main {
  flex-grow: 1; // (1)
}

img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

p, ul, ol, a {
  font-size: 1rem;
  margin: 0;
}

ul {
  list-style: none;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
  font-size: inherit;

  &:hover {
    color: inherit;
  }
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  border: 0;
  border-top: 1px dashed rgba(0, 0, 0, 0.2);
}

.main {
  margin-top: 120px;

  @include down(992) {
    margin-top: 180px;
  }

  @include down(768) {
    margin-top: 150px;
  }
}
