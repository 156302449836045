.itinerary-top {
  text-align: left;
  margin-left: 100px;
  margin-right: 100px;

  @include down(768) {
    margin-left: 30px;
    margin-right: 30px;
    text-align: center;
  }
}

.itinerary-planner {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  text-align: left;
  margin-left: 100px;
  margin-right: 100px;

  @include down(992) {
    flex-direction: column;
    margin-left: 50px;
    margin-right: 50px;
  }

  @include down(768) {
    margin-left: 10px;
    margin-right: 10px;
  }

  &__desc {
    width: 60vw;
    margin-left: spacing(3);

    @include down(992) {
      order: 1;
      margin-top: spacing(5);
    }
    @include down(768) {
      margin: spacing(1);
      width: 100%;
    }
  }

  &__map {
    flex-grow: 1;
    height: 70vh;
    margin-top: 0;
    position: -webkit-sticky;
    position: sticky;
    width: 100%;

    @include down(992) {
      height: 30vh;
    }
  }
}
