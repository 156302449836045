/* ==========================================================================
  Font face
  ========================================================================== */

@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return
            str-slice($string, 1, $index - 1)
            + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  @return $string;
}

// La mixin font-face permet de charger à la volée des polices

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {

  $src: null;
  $extmods: ( eot: "?", svg: "#" + str-replace($name, " ", "_"));
  $formats: ( otf: "opentype", ttf: "truetype");

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
  }

  @font-face {
    font-family:quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}

/* ==========================================================================
  Spacing
  ========================================================================== */

// définition d'espacement sur une base de 8

@function spacing($i) {
  @return calc(8px * #{$i});
}

/* ==========================================================================
  Responsive
  ========================================================================== */

  // Les mixin up et down permettent de faire des media queries en up et down,
  // plus rapide à écrire qu’une media querie classique.

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}

@mixin up($size) {
  $size: strip-unit($size);
  @media (min-width: $size + 1px) { @content; }
}

@mixin down($size) {
  $size: strip-unit($size);
  @media (max-width: $size * 1px) { @content; }
}
