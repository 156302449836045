/* ==========================================================================
  Font-face
  ========================================================================== */

@include font-face('Metropolis', '../../fonts/Metropolis-Thin', 100, null, woff2 woff ttf);
@include font-face('Metropolis', '../../fonts/Metropolis-ExtraLight', 200, null, woff2 woff ttf);
@include font-face('Metropolis', '../../fonts/Metropolis-Light', 300, null, woff2 woff ttf);
@include font-face('Metropolis', '../../fonts/Metropolis-Regular', 400, null, woff2 woff ttf);
@include font-face('Metropolis', '../../fonts/Metropolis-Medium', 500, null, woff2 woff ttf);
@include font-face('Metropolis', '../../fonts/Metropolis-SemiBold', 600, null, woff2 woff ttf);
@include font-face('Metropolis', '../../fonts/Metropolis-ExtraBold', 800, null, woff2 woff ttf);
@include font-face('Metropolis', '../../fonts/Metropolis-Bold', 900, null, woff2 woff ttf);

@include font-face('Open Sans', '../../fonts/OpenSans-Light', 300, null, woff2 woff ttf);
@include font-face('Open Sans', '../../fonts/OpenSans-Regular', 400, null, woff2 woff ttf);
@include font-face('Open Sans', '../../fonts/OpenSans-SemiBold', 600, null, woff2 woff ttf);
@include font-face('Open Sans', '../../fonts/OpenSans-ExtraBold', 800, null, woff2 woff ttf);
@include font-face('Open Sans', '../../fonts/OpenSans-Bold', 900, null, woff2 woff ttf);








