.card {

  &-article {
    padding: 25px;

    @include down(768) {
      padding-left: 15px;
      padding-right: 15px;
    }

    &__title {
      margin-bottom: spacing(1);
      font-size: 1.5rem;
      color: $color-visite;
    }

    &__img {
      width: 80%;
      max-height: 280px;
      object-fit: cover;
      margin: 10px auto;
      box-shadow: $box-shadow-img;

      @include down(992) {
        width: 100%;
        height: auto;
      }
    }

    &__date {
      color: $color-text;

      span {
        margin-right: 10px;
      }
    }

    &__desc {
      margin-top: spacing(3);
      margin-bottom: spacing(3);
    }

    &__more {
      margin-top: spacing(3);

      @include up(992) {
        text-align: right;
        margin-top: spacing(6);
      }
    }
  }

  &-roadbook {

    &__img {
      width: 80%;
      max-height: 280px;
      object-fit: cover;
      margin: 10px auto;
      box-shadow: $box-shadow-img;

      @include down(992) {
        width: 100%;
        height: auto;
      }
    }

    &__title {
      margin: spacing(1) 0;
    }

    &__desc {
      margin: spacing(2) 0;
    }

    &__date {
      color: $color-primary;

      span {
        margin-right: 10px;
      }
    }

    &__more {
      margin-bottom: spacing(3);
    }
  }
}
