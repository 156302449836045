.header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: transparent;
  z-index: 100;

  &.active {
    background-color: $color-white;
    border-bottom: 1px solid #DEE2E6;
    box-shadow: 0 2px 2px -2px rgba(0,0,0,.2);
    z-index: 100;
  }
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 0;
  max-width: 1800px;
  margin: 0 auto;
  color: $color-text;

  @include down(992) {
    flex-direction: column;
  }
  @include down(768) {
    padding: 1rem 0 1.5rem 0;
  }

  &__brand {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-left: 50px;

    @include down(992) {
      margin-bottom: spacing(3);
      margin-left: 0;
    }
    @include down(768) {
      width: 100%;
      margin-bottom: spacing(2);
    }

    &-logo {
      width: 280px;

      @include down(768) {
        margin-left: spacing(1);
        width: 40vw;
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    margin-right: 50px;

    @include down(992) {
      margin-right: 0;
    }

    &-items {
      display: flex;
    }

    &-item {
      margin: 0 spacing(2);
      font-weight: 600;
      padding-bottom: 0.4rem;
      @extend %underlined;

      @include down(768) {
        margin: 0 spacing(1.5);
        font-size: 1rem;
      }

      &.color {
        color: $color-primary-hover;
      }

      &.active {
        color: $color-text-dark;
      }
    }

    &-auth {
      margin-left: spacing(3);
      margin-right: spacing(5);

      @include down(768) {
        display: none;
      }

      &--mobile {
        display: none;

        @include down(768) {
          display: block;
          margin-right: spacing(2);
        }
      }
    }

    &-socials {
      display: flex;

      @include down(768) {
        display: none;
      }
    }

    &-social {
      margin-right: 2rem;
      font-size: 1.6rem;
      color: $color-text;
      cursor: pointer;
      transition: 0.3s ease-in;

      &.facebook:hover {
        color: $color-social-fcb;
        transform: scale(1.2);
      }
      &.twitter:hover {
        color: $color-social-twitter;
        transform: scale(1.2);
      }
    }
  }

  &__avatar {
    height: 40px;
    width: 40px;

    @include down(768) {
      height: 30px;
      width: 30px;
    }
  }

  .dropdown {
    &-menu {
      background-color: $color-secondary;
      border-radius: 8px;
    }
    &-item {
      color: $white;

      &:hover {
        background-color: $color-text;
      }
    }
  }
}
