.map__container,
.leaflet-container {
    width: 100%;
    height: 70vh;
    box-shadow: $box-shadow-btn-hover;
    z-index: 1;

    @include down(992) {
        height: 30vh;
    }
}

.map__container-mini {
    width: 90%;
    margin-bottom: spacing(3);

    @include down(768) {
        width: 100%;
    }
}

.map__container-mini,
.map__container-mini .leaflet-container {
    height: 300px;
    box-shadow: $box-shadow-btn-hover;
    z-index: 1;

    @include down(992) {
        height: 30vh;
    }
}

.popup {
    text-align: center;

    &__type {
        font-weight: 700;
        font-size: 1.1rem;
        border-bottom: 1px solid #dee2e6;
        padding-bottom: spacing(2);
        text-transform: uppercase;
    }

    &__title {
        font-weight: 500;
    }

    &__date {
        font-size: 0.9rem;
        color: $color-text;
    }

    &__desc {
        color: $color-visite;
        font-size: 0.9rem;
    }

}


