.roadbooks {
  text-align: left;
  width: 100%;

  @include down(768) {
    text-align: center;
  }

  &__welcome {
    font-weight: 700;
    font-size: 1.4rem;
  }

  &__create {
    margin-top: spacing(3);
  }

  &__heading {
    margin-top: spacing(5);
    text-align: center;
  }
}

.roadbook-gallery {
  text-align: center;

  &__heading {
    text-align: left;
    margin-top: spacing(7);
    margin-bottom: spacing(3);
    font-size: 1.5rem;
    color: $color-visite;

    @include down(768) {
      text-align: center;
    }
  }
}
