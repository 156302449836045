.home {

  &__heading {
    font-size: 1.6rem;
    @include down(1200) { font-size: 1.4rem; }
    @include down(992) { font-size: 1.3rem; }
    @include down(768) { font-size: 1.2rem; }
  }

  &-hero {
    margin-bottom: spacing(8);

    &__link {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &-title {
        @include down(1200) { font-size: 2.2rem; }
        @include down(992) { font-size: 2rem; }
        @include down(768) { font-size: 1.7rem; }
      }
    }

    &__img {
      @include down(768) {
        order: -1;
        margin-bottom: spacing(3)
      }
    }
  }

  &-tutorial {
    background-color: $color-secondary;
    padding: spacing(5);
    color: $color-white;
    width: 100vw;

    @include down(768) {
      width: 100%;
    }

    .home__heading {
      color: $color-visite;
    }

    .presentation__heading {
      color: $color-primary;
      font-weight: 600;
    }

    p {
      font-weight: 600;
    }
  }

  &-presentation {
    width: 70%;
    margin: spacing(5) auto;
    @include down(768) {
      width: 100%;
    }

    &__text {
      margin-bottom: spacing(2);

      @include up(768) {
        font-size: 1.1rem;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &-share {
    width: 60%;
    background-color: $color-light-grey;
    padding: spacing(5) 0;
    margin: spacing(10) auto;

    @include down(768) {
      width: 100%;
      margin: spacing(8) auto;
    }

    &__form {

      &-link {
        display: flex;
        justify-content: center;
        align-items: center;

        @include down(768) {
          flex-direction: column;
        }

        input {
          flex: 0.9;
          margin-right: spacing(2);

          @include down(992) {
            flex: 1;
          }

          @include down(768) {
            width: 100%;
            margin-bottom: spacing(2);
            margin-right: 0;
          }
        }
        button {
          display: inline;
        }
      }
    }
  }
}
