.roadbook-show {
  text-align: left;
  margin-top: spacing(5);

  &__heading {

    @include down(768) {
      text-align: center;
    }

    span {
      font-size: 1.5rem;
      color: $color-text;

      @include down(768) {
        display: block;
        margin-top: spacing(3);
      }
    }

    &-sub {
      margin-top: spacing(5);
      margin-bottom: spacing(3);

      @include down(768) {
        text-align: center;
      }
    }

    &-desc {
      margin-bottom: spacing(3);

      &--info {
        color: $color-visite;
        text-align: center;
        margin-top: spacing(2);
      }
    }
  }

  &-gestion {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $color-light-grey;
    padding: spacing(3);
    text-align: center;

    @include down(768) {
      flex-direction: column;
      justify-content: center;
    }

    &__check {
      display: flex;
      font-weight: 600;

      @include down(768) {
        margin-top: spacing(3);
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      &-wrapper {

        .share {
          color: $color-visite;

          @include down(768) {
            display: block;
            margin-top: spacing(1)
          }
        }
      }

      .form-check {
        margin-left: spacing(2);
        margin-bottom: spacing(1);

        @include down(768) {
          margin-top: spacing(1);
          margin-bottom: spacing(2);
        }
      }
    }
  }

  &-map {
    align-items: center;

    &__visualization {
      margin-bottom: spacing(3);

      img {
        margin-bottom: spacing(3);
      }
    }

    &__resume {
      font-weight: 600;

      &-item {
        margin-top: spacing(2);
      }

      .icon {
        color: $color-primary-hover;
        margin-right: spacing(1);
        font-size: 1.2rem;
      }

      .address {
        margin-left: spacing(4);
        font-weight: 500;
      }

      .time {
        margin-left: spacing(1);
        font-weight: 500;
      }
    }
  }

  &-itinerary {

    @include down(768) {
      text-align: center;
    }

    &__link {
      margin-bottom: spacing(5);
    }
  }

  &-informations {
    border-left: 1px dashed $color-text;
    padding-left: spacing(5);

    @include down(768) {
      border-left: none;
      padding-left: 0;
      text-align: center;
    }
  }

  &-checklist {
    border-left: 1px dashed $color-text;
    padding-left: spacing(5);

    @include down(768) {
      border-left: none;
      padding-left: 0;
    }
  }
}

.MuiIconButton-colorPrimary {
  color: $color-visite !important;
}
