
.btn-motoo {
  color: $color-white;
  background-color: $color-primary;
  border-color: $color-primary;
  transition: 0.3s ease-in-out;
  box-shadow: $box-shadow-btn;

  &-outline {
    color: $color-primary;
    border-color: $color-primary;
    transition: 0.3s ease-in-out;
    box-shadow: $box-shadow-btn;

    &:hover {
      color: $color-primary;
      border-color: $color-primary;
      transform: scale(1.1);
      box-shadow: $box-shadow-btn-hover;
    }
  }

  &-outline-dark {
    color: $color-text;
    border-color: $color-text;
    transition: 0.3s ease-in-out;
    box-shadow: $box-shadow-btn;
    border-radius: 40px;

    &:hover {
      color: $color-white;
      background-color: $color-text;
      transform: scale(1.1);
      box-shadow: $box-shadow-btn-hover;
    }
  }

  &-blue {
    color: $color-white;
    border-color: $color-visite;
    background-color: $color-visite;
    transition: 0.3s ease-in-out;
    box-shadow: $box-shadow-btn;

    &:hover {
      color: $color-white;
      background-color: $color-visite-hover;
      border-color: $color-visite-hover;
      transform: scale(1.1);
      box-shadow: $box-shadow-btn-hover;
    }
  }

  &-outline-blue {
    color: $color-visite;
    border-color: $color-visite;
    transition: 0.3s ease-in-out;
    box-shadow: $box-shadow-btn;

    &:hover {
      color: $color-white;
      background-color: $color-visite;
      transform: scale(1.1);
      box-shadow: $box-shadow-btn-hover;
    }
  }

  &:hover {
    color: $color-white;
    background-color: $color-primary-hover;
    border-color: $color-primary-hover;
    transform: scale(1.1);
    box-shadow: $box-shadow-btn-hover;
  }
}
