.content {
  text-align: center;
  margin-top: spacing(3);
  max-width: 1800px;
  width: 100%;

  @include down(768) {
    padding-right: 15px;
    padding-left: 15px;
  }

  &__form {
    width: 400px;
    margin: 0 auto;

    @include down(768) {
      width: 80%;
    }
  }

  &__info {
    border-top: 1px solid $border;
    padding-top: spacing(2);
    width: 400px;
    margin: 0 auto spacing(5) auto;

    @include down(768) {
      width: 80%;
    }

    span {
      display: inline-block;
      color: $color-visite;
    }

    button {
      margin-left: spacing(3);

      @include down(768) {
        display: block;
        text-align: center;
        margin: spacing(2) auto 0 auto;
      }

    }
  }
}

section {
  margin: spacing(5) 0;

  @include down(768) {
    margin: spacing(3) 0;
  }
}
