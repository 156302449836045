.footer {
  background-color: $color-light-grey;
  margin-top: spacing(5);
  border-top: 1px solid $border;

  &__wrapper {
    max-width: 1800px;
    margin: 0 spacing(8);
    padding-top: spacing(5);
    padding-bottom: spacing(5);

    @include down(1380) {
      margin: 0 spacing(5);
    }

    @include down(768) {
      padding-top: spacing(3);
      padding-bottom: spacing(3);
      text-align: center;
    }
  }

  // menu de navigation

  &-link {
    display: flex;
    justify-content: space-between;
    margin-bottom: spacing(5);

    @include down(768) {
      flex-direction: column;
    }

    &__menu {

      @include down(768) {
        margin-bottom: spacing(3);
      }

      &-heading {
        font-weight: 700;
      }
      &-item {
        margin-top: spacing(1);
        transition: 0.2s ease-in;

        @include down(768) {
          margin-bottom: 0;
        }

        &:hover {
          color: $color-text-dark;
        }
      }
    }

    // Réseaux sociaux

    &__social {
      margin-right: 50px;

      @include down(1200) {
        margin-right: 30px;
      }
      @include down(992) {
        margin-right: 0;
      }

      &-item {
        margin-top: spacing(3);
        font-size: 2rem;
        color: $color-text;
        cursor: pointer;
        transition: 0.3s ease-in;
        margin-right: spacing(3);

        @include down(768) {
          margin-right: 0;

          &:first-child {
            margin-right: spacing(3);
          }
        }


        &.facebook:hover {
          color: $color-social-fcb;
          transform: scale(1.2);
        }
        &.twitter:hover {
          color: $color-social-twitter;
          transform: scale(1.2);
        }
      }
    }
  }

  // logo

  &-logo {
    width: 180px;
    margin-bottom: spacing(2);

    @include down(768) {
      width: 35vw;
      margin: 10px auto;
    }
  }

  // copyright

  &-copyright {
    &__content {
      font-size: 14px;
    }
  }
}
