.article {

  @include down(768) {
    padding-left: 15px;
    padding-right: 15px;
  }

  &__back {
    margin-bottom: spacing(5);
  }

  &__img {
    width: 60%;
    object-fit: cover;
    margin: 10px auto;
    box-shadow: $box-shadow-img;

    @include down(992) {
      width: 100%;
      height: auto;
    }
  }

  &__date {
    margin: spacing(3);
  }

  &__content {
    margin-top: spacing(1);
  }
}
