.roadbook-form {
  text-align: center;

  &__wrapper {
    align-items: center;
    justify-content: center;
  }

  &__col {
    padding: spacing(5);

    @include down(768) {
      padding: 0;
    }
  }

  &__img {
    width: auto;
    max-height: 250px;
    object-fit: cover;
    box-shadow: $box-shadow-img;

    @include down(768) {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__submit {
    margin-left: spacing(3.5);

    @include down(768) {
      margin-left: 0;
      margin-top: spacing(2);
    }
  }
}
