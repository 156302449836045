.itinerary {
  text-align: left;
  width: 100%;

  &__todo-container {
    margin-top: spacing(5);
  }

  &__title {
    margin-top: spacing(5);

    span {
      font-size: 1.5rem;
      color: $color-text;

      @include down(768) {
        display: block;
        margin-top: spacing(3);
      }
    }
  }

  @include down(768) {
    text-align: center;
  }

  &__link {
    margin-bottom: spacing(3);
  }

  &__step {
    text-align: left;
    margin-right: spacing(3);

    @include down(768) {
      margin-right: 0;
    }

    &-item {
      display: flex;
      align-items: center;
      margin-bottom: 6px;

      &-icon {
        width: 40px;
        display: inline-block;
        text-align: center;
        flex-shrink: 0;

        .distance-icon {
          color: #b1b1b1;
        }
      }

      &-content {

        &-date {
          font-size: 0.9rem;
          font-weight: 600;
        }

        &-distance {
          color: $color-primary;
          font-size: 0.8rem;
        }

        &-desc {
          color: $color-visite;
          font-size: 0.8rem;
          margin-top: spacing(1);
        }
      }
    }

    .restaurant-icon { color: $color-restaurant; }
    .visite-icon { color: $color-visite; }
    .location-icon { color: $color-location; }
    .hotel-icon { color: $color-hotel; }

    &--delete {
      color: #e2acac;
      font-size: 0.9rem;
      border: none;
      background-color: inherit;
      transition: 0.3s ease-in-out;

      &:hover {
        transform: scale(1.3);
      }
    }
  }
}
