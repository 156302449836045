.form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: spacing(4);
  width: 100%;

  &__bloc {
    margin-bottom: spacing(3);
    width: 100%;
  }
}

.form-check-input {
  border-color: $color-visite;

  &:checked {
    background-color: $color-visite;
    border-color: $color-visite;
  }
}

