/* ==========================================================================
  Color
  ========================================================================== */

$color-primary: #FA6400;
$color-secondary: #262626;
$color-light-grey: #F3F3F3;

// marqueurs et icones

$color-restaurant: #F8AB13;
$color-hotel: #685FA7;
$color-visite: #44BABB;
$color-location: #C2D342;

$color-black: #000;
$color-white: #FFF;

$color-text: #666666;
$color-text-dark: #111111;
$color-mark: #FFE1AD;
$border: #e0e0e0;

// button colors
$color-primary-hover: #f67d2b;
$color-visite-hover: #60d1d2;

/* icones réseaux sociaux */

$color-social-fcb: #408fb8;
$color-social-twitter: #5ebbf6;
$color-social-youtube: #cb3030;
$color-social-instagram: #ca3e9c;

/* ==========================================================================
  Font-face
  ========================================================================== */

$f-metropolis: 'Metropolis', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
sans-serif;

$f-opensans: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
sans-serif;

/* ==========================================================================
  Box-shadow
  ========================================================================== */

$box-shadow-img: 0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
$box-shadow-btn: 0 4px 14px 0 rgba(0, 0, 0, 0.1);
$box-shadow-btn-hover: 0 6px 20px 0 rgba(93, 93, 93, 0.2);

/* ==========================================================================
  Bike color
  ========================================================================== */

$bodywork-progress : #cdb250;  // carrosserie
$bodywork-finish : #cd5a50;  // carrosserie
$frame: #AABABF;  // fourche











