
.Toastify__toast {
  border-radius: 255px 25px 225px 25px/25px 225px 25px 255px;
  text-decoration: none;

  @include down(768) {
    border-radius: 0px;
  }

  &--info {
    color: $color-white;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  &--success {
    color: $color-white;
    background-color: #47d1af;
    border-color: #47d1af;
  }
  &--error {
    color: $color-white;
    background-color: #ee5744;
    border-color: #ee5744;
  }
}

.toast-icon {
  margin-right: 10px;
  font-size: 20px;

  &.success {
    color: green;
  }

  &.warning {
    color: #a09e9e;
  }

  &.danger {
    color: red;
  }
}
